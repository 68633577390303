import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogRoll from "../components/blogRoll"

const IndexPage = () => (
  <Layout isHome={true}>
    <Seo title="Home" />

    <div className="container" style={{ padding: "0px" }}>
      <BlogRoll />
    </div>
  </Layout>
)

export default IndexPage
